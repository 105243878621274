import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SiteText from '../components/sitetext';
import Img404 from '../images/four.svg';

const Error404 = ({ location }) => {
  const { pathname } = location;
  return (
    <Layout title="Page not found">
      <SiteText>
        <h1>Filmriss?!?</h1>
        <div id="mainText">
          <Img404 height="200px" style={{ marginTop: '5px' }} />
          <p>Die von Ihnen aufgerufene Webseite</p>
          <br />
          <b>
            www.vati-productions.de
            {pathname}
          </b>
          <br />
          <p>existiert leider nicht</p>
        </div>
      </SiteText>
    </Layout>
  );
};
export default Error404;


Error404.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

Error404.defaultProps = {
  location: {
    pathname: '',
  },
};
